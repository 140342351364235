.@{steps-prefix-cls}-vertical {
  display: flex;
  flex-direction: column;

  > .@{steps-prefix-cls}-item {
    display: block;
    flex: 1 0 auto;
    padding-left: 0;
    overflow: visible;

    .@{steps-prefix-cls}-item-icon {
      float: left;
      margin-right: @steps-vertical-icon-width;
    }

    .@{steps-prefix-cls}-item-content {
      display: block;
      min-height: 48px;
      overflow: hidden;
    }

    .@{steps-prefix-cls}-item-title {
      line-height: @steps-icon-size;
    }

    .@{steps-prefix-cls}-item-description {
      padding-bottom: 12px;
    }
  }

  > .@{steps-prefix-cls}-item
    > .@{steps-prefix-cls}-item-container
    > .@{steps-prefix-cls}-item-tail {
    position: absolute;
    top: 0;
    left: @steps-vertical-tail-width;
    width: 1px;
    height: 100%;
    padding: @steps-icon-size + 6px 0 6px;

    &::after {
      width: 1px;
      height: 100%;
    }
  }

  > .@{steps-prefix-cls}-item:not(:last-child)
    > .@{steps-prefix-cls}-item-container
    > .@{steps-prefix-cls}-item-tail {
    display: block;
  }

  > .@{steps-prefix-cls}-item
    > .@{steps-prefix-cls}-item-container
    > .@{steps-prefix-cls}-item-content
    > .@{steps-prefix-cls}-item-title {
    &::after {
      display: none;
    }
  }

  &.@{steps-prefix-cls}-small .@{steps-prefix-cls}-item-container {
    .@{steps-prefix-cls}-item-tail {
      position: absolute;
      top: 0;
      left: @steps-vertical-tail-width-sm;
      padding: @steps-small-icon-size + 6px 0 6px;
    }
    .@{steps-prefix-cls}-item-title {
      line-height: @steps-small-icon-size;
    }
  }
}
