@import '../../input/style/mixin';

@input-number-prefix-cls: ~'@{ant-prefix}-input-number';

@input-number-wrapper-cls: @input-number-prefix-cls, ~'@{input-number-prefix-cls}-affix-wrapper';

each(@input-number-wrapper-cls, {
  .@{value} {
    &-status-error {
      .status-color(@value, @error-color, @error-color, @input-bg, @error-color-hover, @error-color-outline);
      .status-color-common(@input-number-prefix-cls, @error-color, @error-color, @input-bg, @error-color-hover, @error-color-outline)
    }

    &-status-warning {
      .status-color(@value, @warning-color, @warning-color, @input-bg, @warning-color-hover, @warning-color-outline);
      .status-color-common(@input-number-prefix-cls, @warning-color, @warning-color, @input-bg, @warning-color-hover, @warning-color-outline)
    }
  }
});

.@{input-number-prefix-cls}-affix-wrapper {
  &-status-validating {
    .@{input-number-prefix-cls}-feedback-icon {
      display: inline-block;
      color: @primary-color;
    }
  }

  &-status-success {
    .@{input-number-prefix-cls}-feedback-icon {
      color: @success-color;
      animation-name: diffZoomIn1 !important;
    }
  }
}

.@{input-number-prefix-cls}-group-wrapper {
  &-status-error {
    .group-status-color(@input-number-prefix-cls, @error-color, @error-color);
  }

  &-status-warning {
    .group-status-color(@input-number-prefix-cls, @warning-color, @warning-color);
  }
}
