.operation-unit() {
  color: @link-color;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;

  &:focus,
  &:hover {
    color: @link-hover-color;
  }

  &:active {
    color: @link-active-color;
  }
}
