@import '../../style/themes/index';
@import '../../style/mixins/index';

@breadcrumb-prefix-cls: ~'@{ant-prefix}-breadcrumb';

.@{breadcrumb-prefix-cls} {
  .reset-component();

  color: @breadcrumb-base-color;
  font-size: @breadcrumb-font-size;

  .@{iconfont-css-prefix} {
    font-size: @breadcrumb-icon-font-size;
  }

  a {
    color: @breadcrumb-link-color;
    transition: color 0.3s;

    &:hover {
      color: @breadcrumb-link-color-hover;
    }
  }

  & > span:last-child {
    color: @breadcrumb-last-item-color;

    a {
      color: @breadcrumb-last-item-color;
    }
  }

  & > span:last-child &-separator {
    display: none;
  }

  &-separator {
    margin: @breadcrumb-separator-margin;
    color: @breadcrumb-separator-color;
  }

  &-link {
    > .@{iconfont-css-prefix} + span,
    > .@{iconfont-css-prefix} + a {
      margin-left: 4px;
    }
  }

  &-overlay-link {
    > .@{iconfont-css-prefix} {
      margin-left: 4px;
    }
  }
}

@import './rtl';

@root-entry-name: default;