@media screen and (max-width: @screen-md) {
  .@{backtop-prefix-cls} {
    right: 60px;
    
    &-rtl {
      right: auto;
      left: 60px;
    }
  }
}

@media screen and (max-width: @screen-xs) {
  .@{backtop-prefix-cls} {
    right: 20px;
    
    &-rtl {
      right: auto;
      left: 20px;
    }
  }
}
