.@{steps-prefix-cls} {
  &-rtl {
    direction: rtl;
  }
}

.@{steps-prefix-cls}-item {
  &-icon {
    .@{steps-prefix-cls}.@{steps-prefix-cls}-rtl & {
      margin-right: 0;
      margin-left: 8px;
    }
  }

  &-tail {
    .@{steps-prefix-cls}-rtl & {
      right: 0;
      left: auto;
    }
  }

  &-title {
    .@{steps-prefix-cls}-rtl & {
      padding-right: 0;
      padding-left: 16px;
    }

    &::after {
      .@{steps-prefix-cls}-rtl & {
        right: 100%;
        left: auto;
      }
    }
  }
}

.@{steps-prefix-cls}-horizontal:not(.@{steps-prefix-cls}-label-vertical) {
  .@{steps-prefix-cls}-item {
    .@{steps-prefix-cls}-rtl& {
      padding-right: 16px;
      padding-left: 0;
    }

    &:first-child {
      .@{steps-prefix-cls}-rtl& {
        padding-right: 0;
      }
    }
    &:last-child .@{steps-prefix-cls}-item-title {
      .@{steps-prefix-cls}-rtl& {
        padding-left: 0;
      }
    }
  }
}

// custom-icon
.@{steps-prefix-cls}-item-custom {
  .@{steps-prefix-cls}-item-icon {
    > .@{steps-prefix-cls}-icon {
      .@{steps-prefix-cls}-rtl & {
        right: 0.5px;
        left: auto;
      }
    }
  }
}

// nav
.@{steps-prefix-cls}-navigation {
  &.@{steps-prefix-cls}-small {
    .@{steps-prefix-cls}-item {
      &-container {
        .@{steps-prefix-cls}-rtl& {
          margin-right: -12px;
          margin-left: 0;
        }
      }
    }
  }

  .@{steps-prefix-cls}-item {
    &-container {
      .@{steps-prefix-cls}-rtl& {
        margin-right: -16px;
        margin-left: 0;
        text-align: right;
      }
      .@{steps-prefix-cls}-item-title {
        .@{steps-prefix-cls}-rtl& {
          padding-left: 0;
        }
      }
    }

    &::after {
      .@{steps-prefix-cls}-rtl& {
        right: 100%;
        left: auto;
        margin-right: -2px;
        margin-left: 0;
        transform: rotate(225deg);
      }
    }
  }
}

// small
.@{steps-prefix-cls}-small {
  &.@{steps-prefix-cls}-horizontal:not(.@{steps-prefix-cls}-label-vertical)
    .@{steps-prefix-cls}-item {
    .@{steps-prefix-cls}-rtl& {
      padding-right: 12px;
      padding-left: 0;
    }

    &:first-child {
      .@{steps-prefix-cls}-rtl& {
        padding-right: 0;
      }
    }
  }

  .@{steps-prefix-cls}-item-title {
    .@{steps-prefix-cls}-rtl& {
      padding-right: 0;
      padding-left: 12px;
    }
  }
}

// vertical
.@{steps-prefix-cls}-vertical {
  > .@{steps-prefix-cls}-item {
    .@{steps-prefix-cls}-item-icon {
      .@{steps-prefix-cls}-rtl& {
        float: right;
        margin-right: 0;
        margin-left: @steps-vertical-icon-width;
      }
    }
  }

  > .@{steps-prefix-cls}-item
    > .@{steps-prefix-cls}-item-container
    > .@{steps-prefix-cls}-item-tail {
    .@{steps-prefix-cls}-rtl& {
      right: @steps-vertical-tail-width;
      left: auto;
    }
  }

  &.@{steps-prefix-cls}-small .@{steps-prefix-cls}-item-container {
    .@{steps-prefix-cls}-item-tail {
      .@{steps-prefix-cls}-rtl& {
        right: @steps-vertical-tail-width-sm;
        left: auto;
      }
    }
  }
}

// label
.@{steps-prefix-cls}-label-vertical {
  .@{steps-prefix-cls}-item {
    &-title {
      .@{steps-prefix-cls}-rtl& {
        padding-left: 0;
      }
    }
  }
}

// progress-dot
.@{steps-prefix-cls}-dot,
.@{steps-prefix-cls}-dot.@{steps-prefix-cls}-small {
  .@{steps-prefix-cls}-item {
    &-tail {
      .@{steps-prefix-cls}-rtl& {
        margin: 0 (@steps-description-max-width / 2) 0 0;
      }

      &::after {
        .@{steps-prefix-cls}-rtl& {
          margin-right: 12px;
          margin-left: 0;
        }
      }
    }
    &:first-child .@{steps-prefix-cls}-icon-dot {
      .@{steps-prefix-cls}-rtl& {
        right: 2px;
        left: auto;
      }
    }

    &-icon {
      .@{steps-prefix-cls}-rtl& {
        margin-right: 67px;
        margin-left: 0;
      }

      .@{steps-prefix-cls}-icon-dot {
        .@{steps-prefix-cls}-rtl& {
          float: right;
        }

        /* expand hover area */
        &::after {
          .@{steps-prefix-cls}-rtl& {
            right: -26px;
            left: auto;
          }
        }
      }
    }
  }
}

.@{steps-prefix-cls}-vertical.@{steps-prefix-cls}-dot {
  .@{steps-prefix-cls}-item-icon {
    .@{steps-prefix-cls}-rtl& {
      margin-right: 0;
      margin-left: 16px;
    }
  }
  // https://github.com/ant-design/ant-design/issues/18354
  .@{steps-prefix-cls}-item > .@{steps-prefix-cls}-item-container > .@{steps-prefix-cls}-item-tail {
    .@{steps-prefix-cls}-rtl& {
      right: -9px;
      left: auto;
    }
  }
  .@{steps-prefix-cls}-item:first-child .@{steps-prefix-cls}-icon-dot {
    .@{steps-prefix-cls}-rtl& {
      right: 0;
      left: auto;
    }
  }
  .@{steps-prefix-cls}-item-process .@{steps-prefix-cls}-icon-dot {
    .@{steps-prefix-cls}-rtl& {
      right: -2px;
      left: auto;
    }
  }
}

// RTL Steps with progress
.@{steps-prefix-cls}-rtl.@{steps-prefix-cls}-with-progress.@{steps-prefix-cls}-horizontal.@{steps-prefix-cls}-label-horizontal {
  .@{steps-prefix-cls}-item:first-child.@{steps-prefix-cls}-item-active {
    padding-right: 4px;
  }
}
