// Compatibility for browsers.

// Placeholder text
.placeholder(@color: @input-placeholder-color) {
  // Firefox
  /* stylelint-disable-next-line selector-no-vendor-prefix */
  &::-moz-placeholder {
    opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
  }

  &::placeholder {
    color: @color;
    user-select: none; // https://github.com/ant-design/ant-design/pull/32639
  }

  &:placeholder-shown {
    text-overflow: ellipsis;
  }
}
